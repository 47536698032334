import React from "react";
import {Col, Row} from "react-bootstrap";

interface IProps {
    height: number
    className?: string
}

const Spacer: React.FC<IProps> = (props: IProps) => {
    return <>
        <Row>
            <Col className={props.className} style={{minHeight: props.height + "px"}}>
            </Col>
        </Row>
    </>
}

export default Spacer
