import AWS from "aws-sdk";
import {LogHandler} from "./log-handler";
import axios from "axios";
import {Subscriber} from "rxjs";

type AwsCredentials = {
    AccessKeyId: string
    SecretKey: string
    SessionToken: string
    Expiration: number
}

export enum EventType {
    EVENT_TYPE_S3 = 'S3',
    EVENT_TYPE_SQS = 'SQS',
    EVENT_TYPE_SNS = 'SNS',
    EVENT_TYPE_KINESIS = 'KINESIS',
    EVENT_TYPE_DYNAMO = 'DYNAMO',
}

export type TestEvent = {
    eventType: EventType
    eventPayload: {
        success: boolean
        message: string
    }
}

export class AwsTestHandler {
    public exfiltrationPayload = '{"data": "Company secrets!!!"}'
    private awsObject
    private ranTestCounter = 0
    private failedTestCounter = 0
    private logger: LogHandler
    subscriber: Subscriber<any>

    constructor(logger: LogHandler) {
        this.logger = logger
        AWS.config.logger = logger;
        AWS.config.region = process.env.REACT_APP_AWS_CONFIG_REGION;
        this.awsObject = AWS
        this.subscriber = new Subscriber<any>()
    }

    initializeTestData = (): AwsTestHandler => {
        // const navigator = window.navigator;
        // for (const i in navigator) {
        //     // @ts-ignore
        //     this.exfiltrationPayload[i] = navigator[i]
        // }
        return this
    }

    setSubscriber = (subscriber: Subscriber<any>): AwsTestHandler => {
        this.subscriber = subscriber
        return this
    }

    getRanTestsCounter = (): number => {
        return this.ranTestCounter
    }

    incrementRanTestCounter = (): AwsTestHandler => {
        this.ranTestCounter++
        return this
    }

    resetRanTestCounter = (): AwsTestHandler => {
        this.ranTestCounter = 0
        return this
    }

    getFailedTestCounter = (): number => {
        return this.failedTestCounter
    }

    incrementFailedTestCounter = (): AwsTestHandler => {
        this.failedTestCounter++
        return this
    }

    resetFailedTestCounter = (): AwsTestHandler => {
        this.failedTestCounter = 0
        return this
    }

    runAllTests = () => {
        const endpoint = process.env.REACT_APP_AWS_CREDENTIALS_ENDPOINT ?? ''
        axios(endpoint).then((response) => {
            const credentials: AwsCredentials = response.data
            AWS.config.credentials = new AWS.Credentials({
                accessKeyId: credentials?.AccessKeyId,
                secretAccessKey: credentials?.SecretKey,
                sessionToken: credentials?.SessionToken
            });
            this.awsObject = AWS
            this.runS3Test()
            this.runSQSTest()
            this.runSNSTest()
            this.runKinesisTest()
            this.runDynamoDBTest()
        }).catch()
    }

    makeTestEvent = (eventType: EventType, success: boolean, message: string): TestEvent => {
        return {
            eventType: eventType,
            eventPayload: {
                success: success,
                message: message
            }
        }
    }

    s3TestOnComplete = (err: AWS.AWSError, data: AWS.S3.PutObjectOutput) => {
        const event = this.makeTestEvent(EventType.EVENT_TYPE_S3, !!err, err ? "Error " + err : "Success " + data.ETag)
        this.subscriber.next(event)
    }

    sqsTestOnComplete = (err: AWS.AWSError, data: AWS.SQS.SendMessageResult) => {
        const event = this.makeTestEvent(EventType.EVENT_TYPE_SQS, !!err, err ? "Error " + err : "Success " + data.MessageId)
        this.subscriber.next(event)
    }

    snsTestOnComplete = (err: AWS.AWSError, data: AWS.SNS.PublishResponse) => {
        const event = this.makeTestEvent(EventType.EVENT_TYPE_SNS, !!err, err ? "Error " + err : "Success " + data.MessageId)
        this.subscriber.next(event)
    }

    kinesisTestOnComplete = (err: AWS.AWSError, data: AWS.Kinesis.PutRecordOutput) => {
        const event = this.makeTestEvent(EventType.EVENT_TYPE_KINESIS, !!err, err ? "Error " + err : "Success " + data.ShardId)
        this.subscriber.next(event)
    }

    dynamoDBTestOnComplete = (err: AWS.AWSError, data: AWS.DynamoDB.PutItemOutput) => {
        const event = this.makeTestEvent(EventType.EVENT_TYPE_DYNAMO, !!err, err ? "Error " + err : "Success " + data.Attributes)
        this.subscriber.next(event)
    }

    runS3Test = () => {
        // Create an SQS service object
        const s3 = new this.awsObject.S3({ apiVersion: process.env.REACT_APP_S3_API_VERSION });
        const params = {
            Body: this.exfiltrationPayload,
            Bucket: process.env.REACT_APP_S3_BUCKET ?? '',
            Key: process.env.REACT_APP_S3_KEY ?? ''
        };
        s3.putObject(params, this.s3TestOnComplete);
    }

    runSQSTest = () => {
        // Create an SQS service object
        const sqs = new this.awsObject.SQS({ apiVersion: process.env.REACT_APP_SQS_API_VERSION });
        const params = {
            MessageBody: this.exfiltrationPayload,
            QueueUrl: process.env.REACT_APP_SQS_QUEUE_URL ?? ''
        };
        sqs.sendMessage(params, this.sqsTestOnComplete);
    }

    runSNSTest = () => {
        // Create publish parameters
        const params = {
            Message: this.exfiltrationPayload,
            TopicArn: process.env.REACT_APP_SNS_TOPIC_ARN ?? ''
        };
        const sns = new this.awsObject.SNS({ apiVersion: process.env.REACT_APP_SNS_API_VERSION  })
        sns.publish(params, this.snsTestOnComplete)
    }

    runKinesisTest = () => {
        // create Amazon Kinesis service object
        const kinesis = new this.awsObject.Kinesis({
            apiVersion: process.env.REACT_APP_KINESIS_API_VERSION
        });

        // Create the Amazon Kinesis record
        // @ts-ignore
        const partitionKey = 'partition-' + this.awsObject.config.credentials?.identityId
        const params = {
            StreamName: process.env.REACT_APP_KINESIS_STREAM_NAME ?? '',
            Data: this.exfiltrationPayload,
            PartitionKey: partitionKey
        }
        kinesis.putRecord(params, this.kinesisTestOnComplete)
    }

    runDynamoDBTest = () => {
        // Create the DynamoDB service object
        const dynamoDB = new this.awsObject.DynamoDB({ apiVersion: process.env.REACT_APP_DYNAMODB_API_VERSION });
        const params = {
            TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME ?? '',
            Item: {
                'data': { S: this.exfiltrationPayload }
            }
        };
        // Call DynamoDB to add the item to the table
        dynamoDB.putItem(params, this.dynamoDBTestOnComplete);
    }
}
