import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Accordion, Button, Col, Container, Row, Table} from 'react-bootstrap';
import Spacer from "./components/spacer";
import {AwsTestHandler, EventType, TestEvent} from "./handlers/aws-test-handler";
import {FaCheckCircle, FaPlayCircle, FaMinusCircle, FaRedo, FaSpinner, FaTimesCircle} from 'react-icons/fa';
import Header from "./components/header";
import {LOG_MODE, LogHandler} from "./handlers/log-handler";
import {Observable} from "rxjs";

const App = () => {

    const logger = new LogHandler(LOG_MODE.OUTPUT)
    const awsTestHandler = new AwsTestHandler(logger)
    const awsTestCount = Object.getOwnPropertyNames(awsTestHandler).filter(word => word.endsWith('Test')).length;

    const observable = new Observable((subscriber) => {
        awsTestHandler.setSubscriber(subscriber)
    });

    const logDetailsRef = useRef(null)
    const runTestsRef = useRef(null)
    const howItWorksRef = useRef(null)

    // -1 = Tested and Vulnerable
    // 0 = Not Tested / Neutral
    // 1 = Looks good
    // 2 - Testing in Progress
    const [s3TestResult, setS3TestResult] = useState(0)
    const [sqsTestResult, setSqsTestResult] = useState(0)
    const [snsTestResult, setSnsTestResult] = useState(0)
    const [kinesisTestResult, setKinesisTestResult] = useState(0)
    const [dynamoTestResult, setDynamoTestResult] = useState(0)
    const [testCounter, setTestCounter] = useState(0)
    const [failedTestCounter, setFailedTestCounter] = useState(0)
    const [testsCompleted, setTestsCompleted] = useState(false)
    const [showOutput, setShowOutput] = useState(false)
    const [faqActiveKey, setFaqActiveKey] = useState("")


    const handleCounterAndReset = (eventSuccess: boolean, delayCounter: number) => {
        const counter = awsTestHandler.incrementRanTestCounter().getRanTestsCounter()
        setTimeout(() => {
            setTestCounter(counter)
        }, delayCounter)

        // all tests completed
        if (counter === awsTestCount) {
            setTestsCompleted(true)
        }

        if(!eventSuccess) {
            const failedCounter = awsTestHandler.incrementFailedTestCounter().getFailedTestCounter()
            setFailedTestCounter(failedCounter)
        }

        // all tests ran and nothing failed
        if (counter === awsTestCount && awsTestHandler.getFailedTestCounter() === 0) {
            setFailedTestCounter(0)
        }
    }

    const s3TestOnComplete = (testEvent: TestEvent) => {
        setS3TestResult(testEvent?.eventPayload?.success ? 1 : -1)
        handleCounterAndReset(testEvent?.eventPayload?.success, 60)
    }

    const sqsTestOnComplete = (testEvent: TestEvent) => {
        setSqsTestResult(testEvent?.eventPayload?.success ? 1 : -1)
        handleCounterAndReset(testEvent?.eventPayload?.success, 100)
    }

    const snsTestOnComplete = (testEvent: TestEvent) => {
        setSnsTestResult(testEvent?.eventPayload?.success ? 1 : -1)
        handleCounterAndReset(testEvent?.eventPayload?.success, 150)
    }

    const kinesisTestOnComplete = (testEvent: TestEvent) => {
        setKinesisTestResult(testEvent?.eventPayload?.success ? 1 : -1)
        handleCounterAndReset(testEvent?.eventPayload?.success, 150)
    }

    const dynamoDBTestOnComplete = (testEvent: TestEvent) => {
        setDynamoTestResult(testEvent?.eventPayload?.success ? 1 : -1)
        handleCounterAndReset(testEvent?.eventPayload?.success, 250)
    }

    const runTests = () => {
        // @ts-ignore
        logDetailsRef?.current?.scrollIntoView()
        awsTestHandler.initializeTestData()
        awsTestHandler.runAllTests()
        setS3TestResult(2)
        setSqsTestResult(2)
        setSnsTestResult(2)
        setKinesisTestResult(2)
        setDynamoTestResult(2)
    }

    const handleTestEvent = (testEvent: TestEvent) => {
        switch (testEvent?.eventType) {
            case EventType.EVENT_TYPE_S3:
                s3TestOnComplete(testEvent)
                break
            case EventType.EVENT_TYPE_SQS:
                sqsTestOnComplete(testEvent)
                break
            case EventType.EVENT_TYPE_SNS:
                snsTestOnComplete(testEvent)
                break
            case EventType.EVENT_TYPE_KINESIS:
                kinesisTestOnComplete(testEvent)
                break
            case EventType.EVENT_TYPE_DYNAMO:
                dynamoDBTestOnComplete(testEvent)
                break
            default:
                console.error('Unknown Event')
        }
    }

    const subscription = observable.subscribe({
        next(event) {
            const testEvent: TestEvent = JSON.parse(JSON.stringify(event))
            handleTestEvent(testEvent)
        },
        error(err) {
            console.error('something wrong occurred: ' + err);
        },
        complete() {
            console.log('done');
        },
    });

    const cleanupSubscription = () => {
        if(!subscription?.closed) {
            subscription?.unsubscribe()
        }
    }

    useEffect(() => {
        return cleanupSubscription
    }, [])

    const makeResultIcon = (result: number) => {
        switch (result) {
            case -1:
                return <span className="badge bg-danger"><FaTimesCircle color='white' />&nbsp;FAIL</span>
            case 0:
                return <span className="badge bg-secondary"><FaMinusCircle color='white' />&nbsp;-</span>
            case 1:
                return <span className="badge bg-success"><FaCheckCircle color='white' />&nbsp;PASS</span>
            case 2: // replace with a spinner gif
                return <FaSpinner color='grey' />
            default:
                return <FaMinusCircle color='grey' />
        }
    }

    const handleReset = () => {
        setTestsCompleted(false)
        setS3TestResult(0)
        setSqsTestResult(0)
        setSnsTestResult(0)
        setKinesisTestResult(0)
        setDynamoTestResult(0)
        awsTestHandler.resetFailedTestCounter()
        setTestCounter(0)
        logger.clearOutput()
        setShowOutput(false)
        // @ts-ignore
        runTestsRef?.current?.scrollIntoView()
    }

    const handleOutput = () => {
        setShowOutput(!showOutput)
    }

    const handleFaqSecond = () => {
        // @ts-ignore
        howItWorksRef?.current?.scrollIntoView()
        setFaqActiveKey("0")
    }

    return (
        <>
            <div ref={runTestsRef} />
            <Header runTests={runTests} handleReset={handleReset} resetOption={testsCompleted} handleFaqSecond={handleFaqSecond} />
            <div ref={logDetailsRef} />
            <div className="gradientRow"><div className="gradient col" style={{ minHeight: "180px" }} /></div>

            <Container className="mt-5">
                <Spacer height={60} />
                <Row>
                    <Col>
                        <Table responsive>
                            <tbody>
                                <tr  >
                                    <td>1.</td>
                                    <td>Prevent data being uploaded to an external S3 Bucket</td>
                                    <td style={{ textAlign: 'center' }}>{makeResultIcon(s3TestResult)}</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>Prevent data being pushed to an external SQS Queue</td>
                                    <td style={{ textAlign: 'center' }}>{makeResultIcon(sqsTestResult)}</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>Prevent data being published to an external SNS Topic</td>
                                    <td style={{ textAlign: 'center' }}>{makeResultIcon(snsTestResult)}</td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>Prevent data being pushed to an external Kinesis</td>
                                    <td style={{ textAlign: 'center' }}>{makeResultIcon(kinesisTestResult)}</td>
                                </tr>
                                <tr>
                                    <td>5.</td>
                                    <td>Prevent data being written to an external DynamoDB</td>
                                    <td style={{ textAlign: 'center' }}>{makeResultIcon(dynamoTestResult)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: "none" }} />
                                    <td style={{ border: "none" }} />
                                    {
                                        testsCompleted ?
                                            <td className={"d-flex justify-content-center"} style={{ border: "none" }}>
                                                <a onClick={handleReset} style={{ cursor: "pointer" }}>
                                                    <FaRedo fontSize="32px" />
                                                </a>
                                            </td>
                                            : null
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Spacer height={30} />
                {
                    testsCompleted ?
                        <>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <h1 className="pxtitle">
                                        {
                                            failedTestCounter === 0 ?
                                            <div className={'test-pass-fail-result-text'}>
                                                <FaCheckCircle style={{marginRight: 8}} color='green'/> All Tests Passed.
                                            </div> :
                                            <div className={'test-pass-fail-result-text'}>
                                                <FaTimesCircle style={{marginRight: 8}} color='red' /> {failedTestCounter} of {awsTestCount} preventative controls failed.
                                            </div>
                                        }
                                    </h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <div>
                                        <Button style={{ border: "none", color: "#cf4aff", backgroundColor: "#fafafa", fontSize: '18px', width: '140px', maxWidth: '140px' }} onClick={handleOutput} size={"lg"}>
                                            Details
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </> :
                        null
                }
                <Spacer height={30} />
                {
                    <Row>
                        <pre><output style={{ display: showOutput ? "block" : "none" }}>
                        </output></pre>
                    </Row>
                }
            </Container>
            <Container className="mt-5">
                <Spacer height={50} />
                <Row >
                    <Col className="d-flex justify-content-center">
                        <div id={"how-it-works"}>
                            <h1 className="pxtitle" >FAQ</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion alwaysOpen={false} activeKey={faqActiveKey} >
                        <Accordion.Item eventKey={"0"} onClick={() => { setFaqActiveKey(faqActiveKey === "0" ? "" : "0")}}>
                            <Accordion.Header>What is the purpose of this demo?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    This online tool will demonstrate how an insider or malicious actor may take sensitive data from your network, and exfiltrate it through cloud services. When you click <Button style={{ border: "none", backgroundColor: "#cf4dff", fontSize: '15px' }} color="white" size={"sm"}><span><FaPlayCircle fontSize="20px" />&nbsp;Test your controls</span></Button>, our demo tool will assume an AWS role using credentials we provide, then push a set of sample data from your browser session to several cloud services.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"1"} onClick={() => { setFaqActiveKey(faqActiveKey === "1" ? "" : "1")}}>
                            <Accordion.Header>What is cloud data exfiltration?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Cloud data exfiltration can occur either inadvertantly or maliciously through a multitude of methods. Within cloud providers, almost any of the hundreds of available services can be used to steal sensitive and condidential data from the organisation. With the vast majority of data transfers being legitimate, identifying malicious data transfers is difficult; and preventing malicious data transfers is often impossible without a tool such as Kivera.
                                </p>
                                <br/>
                                <p>
                                An example of a method used to exfiltrate data through cloud services could be when a bad actor gains access to a cloud environment through insecure configuration, then uses their access to move laterally through the organisation identifying sensitive data. They may then package the data and use the legitamate cloud network paths to access the cloud providers API and send the sensitive organisation data to their own personal cloud tenant. Using the above test, you can identify if this could be a risk in your organisation.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                    </Col>
                </Row>
                <Spacer height={50} />
            </Container>
            <div className="gradientRow">
                <div className="pipedriveWebForms d-flex justify-content-center col" style={{ flexDirection: "column" }} data-pd-webforms="https://webforms.pipedrive.com/f/31kSCM474keW3c8C1AWzyg20JQ5hdxgwTy2eO5t2WjlKC6V6dvVDmoyq3MsyCORJF" id="42f10037fe80">
                    <h1 className="pxtitle" style={{ textAlign: "center", color: "#ffffff" }}>Stop data exfiltration and cloud misconfigurations. <br /> <span style= {{ color: "rgb(234, 116, 0)"}}>Contact us now. </span>  </h1>
                    {/* <Spacer height={25} /> */}
                    <iframe src="https://webforms.pipedrive.com/f/31kSCM474keW3c8C1AWzyg20JQ5hdxgwTy2eO5t2WjlKC6V6dvVDmoyq3MsyCORJF?embeded=1&amp;uuid=42f10037fe80" name="kivera-42f10037fe80" className="pipedriveWebForms-iframe" />
                </div>
            </div>
        </>
    );
}

export default App;
