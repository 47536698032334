import { Logger } from "aws-sdk/lib/config-base";

export enum LOG_MODE {
    CONSOLE = 'CONSOLE',
    OUTPUT = 'OUTPUT'
}

export class LogHandler implements Logger {
    private readonly defaultLogMode: LOG_MODE = LOG_MODE.CONSOLE

    constructor(logMode?: LOG_MODE) {
        if(logMode) {
            this.defaultLogMode = logMode
        }
    }

    write(chunk: any, encoding: string | undefined, callback: (() => void) | undefined): void {
        console.log('WRITING LOG')
    }

    log(messages: any): void {
        if(this.defaultLogMode === LOG_MODE.CONSOLE) {
            console.log(messages)
        } else if(this.defaultLogMode === LOG_MODE.OUTPUT) {
            const o = document.getElementsByTagName("output")[0];
            o.innerHTML += messages + " <br>";
        }
    }

    warn(message: any): void {
        console.log(message)
    }

    clearOutput = () => {
        const o = document.getElementsByTagName("output")[0];
        o.innerHTML  = "";
    }
}
